import $ from "jquery";
import 'lightbox2';
import '../../global/js/cms.js';

$(document).ready(function() {
    var audioElement = false;
    var isPlaying = false;
    var audioEnabled = false;
    $('#dragon').click(function() {
        if($(window).width() > 800) {
            if(audioEnabled) {
                if (!audioElement) {
                    audioElement = document.createElement('audio');
                    audioElement.setAttribute('src', '/img/HeroesIVmenu.mp3');
                    audioElement.volume = 0.7;

                }
                if (!isPlaying) {
                    audioElement.play();
                    isPlaying = true;
                } else {
                    audioElement.pause();
                    isPlaying = false;
                }
            } else {
                $('#dragon').append('<div class="alert">Uwaga! Ponownie kliknięcie spowoduje odtworzenie dźwięku!</div>');
                audioEnabled = true;
            }
        }
    });

    $('.herotable td').each(function() {
        if($(this).has('img').length) {

        } else {
            var tmp = $(this).html();
            $(this).empty().append(
                $('<div />').append(
                    $('<p />').html(tmp)
                )
            );
        }

    });

    if($('.hero-advanced-skills').length > 0) {
        //var selected = 0;
        HeroAdvencedSkills.init();
    }


    $('[data-open]').css('cursor', 'pointer').click(function() {
        var tab = $(this).attr('data-open');
        $('.tab').hide();
        $('.' + tab).show();

    });
});

var HeroAdvencedSkills = {
    selected: [],
    init: function() {
        var that = this;
        $('.hero-skill').hide();
        $('.hero-advanced-skills li').click(function() {
            var skill = $(this).attr('class').split(' ')[0];

            that.onClick(skill);
        });

        $('.hero-advanced-skill-names a').click(function(e) {
            e.preventDefault();

            var skillName = $(this).attr('href').split('#')[1];

            that.openSkill(skillName);

            return false;
        });
    },
    onClick: function(skill) {
        $('.hero-skill').hide();
        if(this.isSelected(skill)) {
            this.removeSelected(skill);
        } else {
            this.addSelected(skill);
        }
        console.log(this.selected);
    },
    openSkill: function(skillName) {
        while(this.selected.length > 0) {
            var toRemove = this.selected.shift();
            $('.hero-advanced-skills li.' + toRemove).removeClass('selected');
        }
        $('.hero-skill').hide();
        var classes = $('#advanced-' + skillName).parent().show().attr('class').split(' ');
        for(i in classes) {
            if($('.hero-advanced-skills li.' + classes[i]).length) {
                this.addSelected(classes[i]);
            }

        }

    },
    removeSelected: function(skill) {
        $('.hero-advanced-skills li.' + skill).removeClass('selected');
        this.selected.splice( $.inArray(skill, this.selected), 1 );
    },
    isSelected: function(skill) {
        return $.inArray(skill, this.selected) != -1;
    },
    addSelected: function(skill) {
        this.selected.push(skill);
        $('.hero-advanced-skills li.' + skill).addClass('selected');

        var limit = this.getLimit();
        if(this.selected.length >= limit + 1) {
            while(this.selected.length > limit) {
                var toRemove = this.selected.shift();
                $('.hero-advanced-skills li.' + toRemove).removeClass('selected');
            }
        }

        if(this.selected.length == 2) {
            $('.hero-skill.' + this.selected[0] + '.' + this.selected[1]).show();
        }
    },
    getLimit: function() {
        return 2;
    }

};

function removeFromArray(value, array) {

}
